<template>
  <b-row v-if="retrieve_user_rollovers">
    <b-col
      lg="3"
      md="6"
    >
      <b-card class="calc" title="Dados cadastrais">
        <b-card-text><b>Nome:</b> {{user | userDocument("FULLNAME")}}</b-card-text>
        <b-card-text><b>Email:</b> {{user | userDocument("EMAIL")}}</b-card-text>
        <b-card-text><b>Cód convite:</b> {{user | userDocument("INVITECODE")}}</b-card-text>
        <b-card-text><b>CPF:</b> {{user | userDocument("CPF")}}</b-card-text>
        <b-card-text><b>Data de cadastro:</b> {{$datter.convert(user.created_at)}}</b-card-text>
        <b-card-text><b>BTAG:</b> {{user | userDocument("CLICKID")}}</b-card-text>
      </b-card>
    </b-col>
    <b-col
      lg="3"
      md="6">
      <b-card  class="calc" title="Informações básicas">
        <b-card-text><b>Saldo atual:</b> {{wallets.data.filter(d => d.wallet_type_id == 1).length > 0 ? wallets.data.filter(d => d.wallet_type_id == 1)[0].balance : "Sem Info" | brl}} Rol[{{getWalletRollover(1) | brl}}]</b-card-text>
        <b-card-text><b>Bonus em VB:</b>{{wallets.data.filter(d => d.wallet_type_id == 2).length > 0 ? wallets.data.filter(d => d.wallet_type_id == 2)[0].balance : "Sem Info"| brl}} Rol[{{getWalletRollover(2) | brl}}]</b-card-text>
        <b-card-text><b>Bonus em Casino:</b>{{wallets.data.filter(d => d.wallet_type_id == 3).length > 0 ? wallets.data.filter(d => d.wallet_type_id == 3)[0].balance : "Sem Info"| brl}} Rol[{{getWalletRollover(3) | brl}}]</b-card-text>
        <b-card-text><b>Bonus em Sala:</b>{{wallets.data.filter(d => d.wallet_type_id == 5).length > 0 ? wallets.data.filter(d => d.wallet_type_id == 5)[0].balance : "Sem Info"| brl}} Rol[{{getWalletRollover(5) | brl}}]</b-card-text>
        <b-card-text><b>Último acesso:</b> Sem info.</b-card-text>
      </b-card>
    </b-col>
    <b-col
      lg="3"
      md="6"
    >
      <b-card  class="calc" title="Informações adicionais">
        <b-card-text><b>Telefone cadastro:</b> {{user.username}}</b-card-text>
        <b-card-text><b>Verificado em:</b> {{user | userDocument("VALIDPROFILE")}}</b-card-text>
        <b-card-text><b>Endereço:</b> {{user | userDocument("ADDRESS")}}</b-card-text>
        <b-card-text><b>Pais:</b> {{user | userDocument("COUNTRY")}}</b-card-text>
        <b-card-text><b>Nascimento:</b> {{user | userDocument("BIRTHDATE")}}</b-card-text>
        <b-card-text><b>Dep/Sq:</b> {{orders.paginatorInfo.total}}</b-card-text>
      </b-card>
    </b-col>
    <b-col
      lg="3"
      md="6">
      <b-card-actions v-if="$ability.can('edit', 'client') && $ability.can('be', 'company-employee')"  title="Açoes Rápidas" :noActions="true" ref="cardAct">
        <b-row>
          <b-col>
            <b-button class="act-button" variant="gradient-primary" @click="resetPassword()">Trocar Senha</b-button>
            <b-button class="act-button" v-if="$ability.can('edit','client') && $ability.can('be','company-employee')" @click="openRefSidear('changeNameSidebar')" variant="gradient-primary">Trocar nome</b-button>
            <b-button class="act-button" v-if="user.documents.filter(d=> d.type == 'NOBONUS').length == 0" @click="openRefSidear('addPromotionSidebar')" variant="gradient-primary">Add. promo</b-button>
          </b-col>
          <b-col>
            <b-button class="act-button" variant="gradient-primary" @click="openRefSidear('addNotesSidebar')">Add. Nota</b-button>
            <b-button class="act-button" @click="showModal()" variant="gradient-primary">Add. relac.</b-button>
            <b-button v-if="user.documents.filter(d=> d.type == 'BLACKLIST').length == 0" class="act-button" @click="blacklistClient()" variant="gradient-danger">Blacklist</b-button>
            <b-button v-else class="act-button" variant="gradient-success" @click="unBlacklistClient(user.documents.filter(d=> d.type == 'BLACKLIST')[0].id)">Rem. Black.</b-button>
          </b-col>
        </b-row>
        <b-row v-if="$ability.can('edit', 'client') && $ability.can('be', 'company-employee')">
          <b-col>
            <b-button v-if="user.documents.filter(d=> d.type == 'GOODLIST').length == 0" class="act-button" @click="goodlistClient()" variant="gradient-primary">Lista VIP</b-button>
            <b-button v-else class="act-button" variant="gradient-danger" @click="unGoodlistClient(user.documents.filter(d=> d.type == 'GOODLIST')[0].id)">Rem. Lista VIP</b-button>
          </b-col>
          <b-col>
            <b-button v-if="user.status == 'PENDING_VALIDATION' || user.status == 'VALIDATED'" class="act-button" variant="gradient-danger" @click="blockUser()">Block. Conta</b-button>
            <b-button v-else class="act-button" variant="gradient-success" @click="unBlockUser()">Desbl. Conta</b-button>
          </b-col>
        </b-row>
        <b-row v-if="$ability.can('edit', 'client') && $ability.can('be', 'company-employee')">
          <b-col>
            <b-button v-if="user.documents.filter(d=> d.type == 'OFFLINE_DEPOSIT').length == 0" class="act-button" @click="offlineDeposit()" variant="gradient-primary">LIBERAR DEPOSITO OFFLINE</b-button>
          </b-col>
        </b-row>
        <b-row v-if="$ability.can('edit', 'client') && $ability.can('be', 'company-employee')">
          <b-col>
            <b-button v-if="user.documents.filter(d=> d.type == 'INTERNAL').length == 0" class="act-button" @click="demoClient()" variant="warning">CLIENTE DEMO</b-button>
          </b-col>
        </b-row>
        <b-row v-if="$ability.can('edit', 'client') && $ability.can('be', 'company-employee')">
          <b-col>
            <b-button v-if="user.documents.filter(d=> d.type == 'NOBONUS').length == 0" class="act-button" @click="createDoc('NOBONUS')" variant="danger">BLOCK BONUS</b-button>
          </b-col>
          <b-col>
            <b-button class="act-button" @click="openRefSidear('btagSidebar')" variant="warning">BTAG</b-button>
          </b-col>
        </b-row>
      </b-card-actions>
    </b-col>
  </b-row>
</template>
<script>
import {
   BCard, BRow, BCol, BCardText, BButton,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BCardText,
    BButton,
    BCardActions

  },
  props:['wallets','user'],
  data() {
    return {
    }
  },
  apollo:{
    retrieve_user_rollovers:{
      query: gql `query($userId:ID!){
        retrieve_user_rollovers(user_id:$userId){
          id,
          wallet_id,
          act_sum,
          total_sum
        }
      }`,
      client: 'walletClient',
      variables () {
        return {
          userId: this.$route.params.clientId,
        }
      }
    },
    orders:{
      query: gql `query($userId:ID!){
        orders(status: "CONFIRMED", first:100000, user_id:$userId) {
            paginatorInfo {
              total
          }
        }
      }`,
      client: 'cashioClient',
      variables () {
        return {
          userId: this.$route.params.clientId,
        }
      }
    }
  },
  methods:{
    openRefSidear(refname){
      this.$emit('openButtonSidebar', refname)
    },
    offlineDeposit(){
      this.$refs["cardAct"].showLoading = true
      const res = this.$dialog.confirm({ title: 'Deposito OFFLINE',text: 'Voce realmente gostaria de permitir este tipo de depósito?', actions: [{text: 'Não',variant: 'red', key:false},{text: 'Sim',variant: 'green', key:true}]}).then(function(valor) {
        if(valor === true){
          this.$apollo.mutate({
            mutation: gql`mutation (
              $user_id: ID!,
              $type: String,
              $value: String,
              $status: String,
              $audited_by: String,
            ) {
              createUserDocument(
                user_id: $user_id,
                type: $type,
                value: $value,
                status: $status,
                audited_by: $audited_by,
              ){
                id
              }
            }`,
            variables: {
              user_id: this.$route.params.clientId,
              type: "OFFLINE_DEPOSIT",
              value: "TRUE",
              status: "VALIDATED",
              audited_by: "CAS: " + JSON.parse(window.localStorage.getItem('userData')).fullName,
            },
            client: "identityClient"
          }).then((data) => {
            this.$emit('refreshUserData',true);
          }).catch((error) => {
            console.error(error)
          });
        }
        this.$refs["cardAct"].showLoading = false
      }.bind(this), function(motivo) {
      // rejeitada
      });
    },
    blacklistClient(){
      this.$refs["cardAct"].showLoading = true
      const res = this.$dialog.confirm({ title: 'Inclusão de usuário',text: 'Voce realmente gostaria de incluir este cliente na blacklist?', actions: [{text: 'Não',variant: 'red', key:false},{text: 'Sim',variant: 'green', key:true}]}).then(function(valor) {
        if(valor === true){
          this.$apollo.mutate({
            mutation: gql`mutation (
              $user_id: ID!,
              $type: String,
              $value: String,
              $status: String,
              $audited_by: String,
            ) {
              createUserDocument(
                user_id: $user_id,
                type: $type,
                value: $value,
                status: $status,
                audited_by: $audited_by,
              ){
                id
              }
            }`,
            variables: {
              user_id: this.$route.params.clientId,
              type: "BLACKLIST",
              value: "BLACKLIST",
              status: "VALIDATED",
              audited_by: "CAS: " + JSON.parse(window.localStorage.getItem('userData')).fullName,
            },
            client: "identityClient"
          }).then((data) => {
            this.$emit('refreshUserData',true);
          }).catch((error) => {
            console.error(error)
          });
        }
        this.$refs["cardAct"].showLoading = false
      }.bind(this), function(motivo) {
      // rejeitada
      });
    },
    unBlacklistClient(docId){
      this.docId = docId;
      this.$refs["cardAct"].showLoading = true
      const res = this.$dialog.confirm({ title: 'Exclusão de usuário',text: 'Voce realmente gostaria de excluir este cliente na blacklist?', actions: [{text: 'Não',variant: 'red', key:false},{text: 'Sim',variant: 'green', key:true}]}).then(function(valor) {
        if(valor === true){
          this.$apollo.mutate({
            mutation: gql`mutation (
              $id: ID!,
            ) {
              deleteUserDocument(
                id: $id,
              ){
                id
              }
            }`,
            variables: {
              id: this.docId,
            },
            client: "identityClient"
          }).then((data) => {
            this.$emit('refreshUserData',true)
          }).catch((error) => {
            console.error(error)
          })
        }
        this.$refs["cardAct"].showLoading = false
      }.bind(this), function(motivo) {
      // rejeitada
      });
    },
    goodlistClient(){
      this.$refs["cardAct"].showLoading = true
      const res = this.$dialog.confirm({ title: 'Inclusão de usuário',text: 'Voce realmente gostaria de incluir este cliente na lista VIP?', actions: [{text: 'Não',variant: 'red', key:false},{text: 'Sim',variant: 'green', key:true}]}).then(function(valor) {
        if(valor === true){
          this.$apollo.mutate({
            mutation: gql`mutation (
              $user_id: ID!,
              $type: String,
              $value: String,
              $status: String,
              $audited_by: String,
            ) {
              createUserDocument(
                user_id: $user_id,
                type: $type,
                value: $value,
                status: $status,
                audited_by: $audited_by,
              ){
                id
              }
            }`,
            variables: {
              user_id: this.$route.params.clientId,
              type: "GOODLIST",
              value: "GOODLIST",
              status: "VALIDATED",
              audited_by: "CAS: " + JSON.parse(window.localStorage.getItem('userData')).fullName,
            },
            client: "identityClient"
          }).then((data) => {
            this.$emit('refreshUserData',true);
          }).catch((error) => {
            console.error(error)
          });
        }
        this.$refs["cardAct"].showLoading = false
      }.bind(this), function(motivo) {
      });
    },
    demoClient(){
      this.$refs["cardAct"].showLoading = true
      const res = this.$dialog.confirm({ title: 'Inclusão de usuário',text: 'Voce realmente gostaria de incluir como DEMO?', actions: [{text: 'Não',variant: 'red', key:false},{text: 'Sim',variant: 'green', key:true}]}).then(function(valor) {
        if(valor === true){
          this.$apollo.mutate({
            mutation: gql`mutation (
              $user_id: ID!,
              $type: String,
              $value: String,
              $status: String,
              $audited_by: String,
            ) {
              createUserDocument(
                user_id: $user_id,
                type: $type,
                value: $value,
                status: $status,
                audited_by: $audited_by,
              ){
                id
              }
            }`,
            variables: {
              user_id: this.$route.params.clientId,
              type: "INTERNAL",
              value: "INTERNAL",
              status: "VALIDATED",
              audited_by: "CAS: " + JSON.parse(window.localStorage.getItem('userData')).fullName,
            },
            client: "identityClient"
          }).then((data) => {
            this.$emit('refreshUserData',true);
          }).catch((error) => {
            console.error(error)
          });
        }
        this.$refs["cardAct"].showLoading = false
      }.bind(this), function(motivo) {
      });
    },
    createDoc(doc){
      this.$refs["cardAct"].showLoading = true
      const res = this.$dialog.confirm({ title: 'Inclusão de usuário',text: 'Você realmente gostaria de performar esta ação?', actions: [{text: 'Não',variant: 'red', key:false},{text: 'Sim',variant: 'green', key:true}]}).then(function(valor) {
        if(valor === true){
          this.$apollo.mutate({
            mutation: gql`mutation (
              $user_id: ID!,
              $type: String,
              $value: String,
              $status: String,
              $audited_by: String,
            ) {
              createUserDocument(
                user_id: $user_id,
                type: $type,
                value: $value,
                status: $status,
                audited_by: $audited_by,
              ){
                id
              }
            }`,
            variables: {
              user_id: this.$route.params.clientId,
              type: doc,
              value: doc,
              status: "VALIDATED",
              audited_by: "CAS: " + JSON.parse(window.localStorage.getItem('userData')).fullName,
            },
            client: "identityClient"
          }).then((data) => {
            this.$emit('refreshUserData',true);
          }).catch((error) => {
            console.error(error)
          });
        }
        this.$refs["cardAct"].showLoading = false
      }.bind(this), function(motivo) {
      });
    },
    unGoodlistClient(docId){
      this.docId = docId;
      this.$refs["cardAct"].showLoading = true
      const res = this.$dialog.confirm({ title: 'Exclusão de usuário',text: 'Voce realmente gostaria de excluir este cliente na lista VIP?', actions: [{text: 'Não',variant: 'red', key:false},{text: 'Sim',variant: 'green', key:true}]}).then(function(valor) {
        if(valor === true){
          this.$apollo.mutate({
            mutation: gql`mutation (
              $id: ID!,
            ) {
              deleteUserDocument(
                id: $id,
              ){
                id
              }
            }`,
            variables: {
              id: this.docId,
            },
            client: "identityClient"
          }).then((data) => {
            this.$emit('refreshUserData',true)
          }).catch((error) => {
            console.error(error)
          })
        }
        this.$refs["cardAct"].showLoading = false
      }.bind(this), function(motivo) {
      // rejeitada
      });
    },
    resetPassword(){
      this.$refs["cardAct"].showLoading = true
      const res = this.$dialog.confirm({ title: 'Resetar senha',text: 'Voce realmente gostaria de resetar a senha deste cliente?', actions: [{text: 'Não',variant: 'red', key:false},{text: 'Sim, resetar',variant: 'green', key:true}]}).then(function(valor) {
        if(valor === true){
          this.$apollo.mutate({
            mutation: gql`mutation (
              $id: ID!,
              $password: String
            ){
              resetUserPassword(
                id: $id,
                password: $password
              ){
                id
              }
            }`,
            variables: {
              id: this.$route.params.clientId,
              password: "$2y$10$bV1qjCzFD0Kv6yDsyJe8hO5/f3TtOi6w0YdXR.Bdjy/7kldQzeHEi"
            },
            client: "identityClient"
          }).then((data) => {
            let go = this.$dialog.info({ title: 'Senha alterada para:',text: 'novasenha',class:'teste', actions:[{text: 'Ok',variant: 'green', key:false}]})
          }).catch((error) => {
            console.error(error)
          })
        }
        this.$refs["cardAct"].showLoading = false
      }.bind(this), function(motivo) {
      // rejeitada
      });
    },
    showModal(){
      this.$emit('showRelatedModal', true)
    },
    getWalletRollover(walletTypeId){
      let walletId = this.wallets.data.filter(d => d.wallet_type_id == walletTypeId)[0].id;
      let rturn = this.retrieve_user_rollovers.filter(r => r.wallet_id == walletId)
      if(rturn.length > 0){
        return rturn[0].act_sum
      }
      return 0
    },
    blockUser(){
      this.$apollo.mutate({
        mutation: gql`mutation (
          $id: ID!,
        ) {
          blockUser(
            id: $id,
            status: "SUSPENDED"
          ){
            id
          }
        }`,
        variables: {
          id: this.$route.params.clientId,
        },
        client: "identityClient"
      }).then((data) => {
        this.$emit('refreshUserData',true)
      }).catch((error) => {
        console.error(error)
      })
    },
    unBlockUser(){
      this.$apollo.mutate({
        mutation: gql`mutation (
          $id: ID!,
        ) {
          blockUser(
            id: $id,
            status: "PENDING_VALIDATION"
          ){
            id
          }
        }`,
        variables: {
          id: this.$route.params.clientId,
        },
        client: "identityClient"
      }).then((data) => {
        this.$emit('refreshUserData',true)
      }).catch((error) => {
        console.error(error)
      })
    }

  },
  created(){
   
  }

}
</script>

<style>
.calc{
  min-height: calc(100% - 28px);
}
.dialog-actions button[variant="green"]{
  background: #28c76f;
  margin-left:10px;
}
.dialog-actions button{

  vertical-align: middle;
  user-select: none;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  background-color: #ea5455;
  border: 1px solid transparent;
  padding: 0.786rem 1.5rem;
  border-radius: 0.358rem;
  color:#fff;
  font-weight: bold;
}

.dialog-layout-modal-body .modal-header{
  background:transparent!important;
}
.dialog-layout-modal-body .modal-header .close{
  display: none;
}
.modal-footer{
  border:none;
}
</style>
