<template>
  <div>
    <b-sidebar
      id="sidebar-add-promotion"
      v-model="visibility"
      backdrop-variant="primary"
      backdrop
      :right="true"
      title="Adição de promoção"
    >
      <b-card v-if="available">
        <div class="form-block">
          <hr
            data-content="Tipo de liberação"
            class="hr-text"
          >
          <b-form-group>
            <b-form-select
              v-model="promoSelected"
              placeholder="Selecione!"
              size="lg"
              class="mt-3 mb-3"
            >
              <b-form-select-option
                :value="null"
                selected
              >
                Selecione
              </b-form-select-option>

              <b-form-select-option-group
                v-for=" x in group_promotions.keys()"
                :key="x"
                :label="x"
              >

                <b-form-select-option
                  v-for="promo in user_available_promotions.filter(z=>z.group_name == x)"
                  :key="promo.id"
                  :data="promo"
                  :value="promo"
                >{{ promo.title }}</b-form-select-option>
              </b-form-select-option-group>
            </b-form-select>
          </b-form-group>
        </div>
        <div
          v-if="promoSelected"
          class="form-block"
        >
          <hr
            data-content="Informações"
            class="hr-text"
          >
          <div
            v-if="promoSelected.deposit_related == 1 && lastDeposit != null"
            class="info-box"
          >
            <h6 class="text-info fw-bolder">
              Esta é uma promoção referente ao valor de depósito
            </h6>
            <p>O valor aplicado será de {{ promoSelected.value }}% sobre {{ lastDeposit.amount | brl }}</p>
            <p>Total aplicável: {{ promoGrossValue(promoSelected) | brl }}</p>
          </div>
          <div
            v-if="promoSelected.deposit_related == 1 && this.lastDeposit == null"
            class="info-box warning"
          >
            <h6 class="text-danger fw-bolder">
              Não pode adicionar!
            </h6>
            <p class="text-warning">
              Esta promo é sobre deposito, cliente não tem nenhum depósito
            </p>
          </div>
          <div
            v-if="promoSelected.deposit_related == 0"
            class="info-box"
          >
            <h6 class="text-info fw-bolder">
              Esta é uma promoção de valor fixo
            </h6>
            <p>Total aplicável: {{ promoGrossValue(promoSelected) | brl }}</p>
          </div>
          <div
            v-if="promoGrossValue(promoSelected) > promoSelected.max_gain"
            class="info-box warning mt-1"
          >
            <h6 class="text-warning fw-bolder">
              Promoção atingirá o valor maximo
            </h6>
            <p class="text-warning f-big">
              O valor liberado será de {{ promoSelected.max_gain | brl }}
            </p>
          </div>
          <div class="info-box warning">
            <h6 class="text-info text-center fw-bolder mb-1">
              ⚠️Evite erros!⚠️
            </h6>
            <p class="text-info mb-0">
              Libere sempre o depósito primeiro!
            </p>
            <p class="text-info">
              Verifique se o usuário cumpre as regras!
            </p>
          </div>
        </div>
        <alert-toast
          ref="warning-toast"
          :message="this.warningMessage"
          :variant="this.messageVariant"
        />
        <div class="mt-3">
          <b-button
            block
            variant="gradient-primary"
            @click="releasePromo()"
          >
            Tudo ok, liberar
          </b-button>
        </div>
      </b-card>
      <b-card v-if="!available">
        <hr
          data-content="Impedimento"
          class="mb-3 hr-text"
        >
        <p class="display-2 text-center">
          💔
        </p>
        <p class="text-center text-info">
          Usuário ou relacionado possui saque em menos de 48 horas!
        </p>
      </b-card>
      <div
        v-if="loading"
        class="loading-bg"
      >
        <div class="loading">
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
      </div>
    </b-sidebar>
    <!-- <b-button @click="toogleV()">Abre/Fecha</b-button> -->
  </div>
</template>
<script>
import {
  BSidebar,
  BCard,
  BButton,
  BRow,
  BFormInput,
  BFormGroup,
  BFormSelectOptionGroup,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import AlertToast from '@/components/misc/AlertToast.vue'
import { _ } from 'vue-underscore'

const delay = ms => new Promise(res => setTimeout(res, ms))
export default {
  components: {
    BFormSelectOption,
    BSidebar,
    BButton,
    BCard,
    BRow,
    BFormInput,
    BFormSelectOptionGroup,
    BFormGroup,
    BFormSelect,
    AlertToast,
    underscore: _,
  },
  props: ['lastDeposit', 'userWallets'],
  data() {
    return {
      visibility: false,
      liberationType: 0,
      loading: false,
      promoSelected: null,
      warningMessage: null,
      messageVariant: null,
      available: true,
      group_promotions: [],
      user_available_promotions: [],
    }
  },
  apollo: {
    /* user_available_promotions: {
      query: gql`query($userId:ID){
        user_available_promotions(user_id:$userId){
          id,
          name,
          title,
          description,
          group_name,
          deposit_related,
          value,
          wallet_type_id,
          tollerance,
          multiplier,
          max_gain,
        }
      }`,
      client: 'identityClient',
      variables() {
        return {
          userId: this.$route.params.clientId,
        }
      },

    }, */
  },
  created() {
    this.$apollo.query({
      query: gql`
          query($userId:ID){ 
        user_available_promotions(user_id:$userId){
          id,
          name,
          title,
          description,
          group_name,
          deposit_related,
          value,
          wallet_type_id,
          tollerance,
          multiplier,
          max_gain,
        }
      }
        `,
      client: 'identityClient',

      variables: { userId: this.$route.params.clientId },
    }).then(queryResult => {
      // Do stuff with the result.
      this.user_available_promotions = queryResult.data.user_available_promotions
      this.group_promotions = this.groupBy(this.user_available_promotions, x => x.group_name)
      // console.log(this.group_promotions)
    })

    console.log('checkando')
    this.checkWithdraws()
  },
  methods: {
    groupBy(list, keyGetter) {
      const map = new Map()
      list.forEach(item => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
          map.set(key, [item])
        } else {
          collection.push(item)
        }
      })
      return map
    },
    toogleV() {
      this.visibility = !this.visibility
    },
    async releasePromo() {
      if (this.promoSelected == null || (this.promoSelected.deposit_related == 1 && this.lastDeposit == null)) {
        this.warningMessage = 'Selecione uma promoção válida'
        this.messageVariant = 'warning'
        this.$refs['warning-toast'].showAlert()
      } else {
        if (this.promoSelected.deposit_related == 1) {
          this.promoSelected.valueToRelease = this.promoSelected.value * this.lastDeposit.amount / 100 > this.promoSelected.max_gain ? this.promoSelected.max_gain : this.promoSelected.value * this.lastDeposit.amount / 100
        } else {
          this.promoSelected.valueToRelease = this.promoSelected.value > this.promoSelected.max_gain ? this.promoSelected.max_gain : this.promoSelected.value
        }
        this.promoSelected.walletId = this.userWallets.data.filter(w => w.wallet_type_id == this.promoSelected.wallet_type_id)[0].id
        if (this.checkTollerance()) {
          this.processOrder()
        } else {
          this.warningMessage = 'Tolerância da promo atingida, fale com seu supervisor'
          this.messageVariant = 'warning'
          this.$refs['warning-toast'].showAlert()
        }
      }
    },
    processOrder() {
      this.loading = true
      this.$apollo.mutate({
        mutation: gql`mutation (
          $promotion_id:ID,
          $user_id:ID,
          $transaction_id:ID,
          $wallet_id:ID,
          $value_released:Float,
          $released_by:String
        ){
          createUserPromotionReleaseEntry(
            promotion_id:$promotion_id,
            user_id:$user_id,
            transaction_id:$transaction_id,
            wallet_id:$wallet_id,
            value_released:$value_released,
            released_by:$released_by
          ){
            id
          }
        }`,
        variables: {
          promotion_id: this.promoSelected.id,
          user_id: this.$route.params.clientId,
          transaction_id: crypto.randomUUID(),
          wallet_id: this.promoSelected.walletId,
          value_released: this.promoSelected.valueToRelease,
          released_by: JSON.parse(window.localStorage.getItem('userData')).fullName,
        },
        client: 'identityClient',
      }).then(data => {
        this.warningMessage = 'Promoção liberada com sucesso!'
        this.messageVariant = 'success'
        this.loading = false
        this.$refs['warning-toast'].showAlert()
        this.toogleV()
        this.$router.go(this.$router.currentRoute)
      }).catch(error => {
        console.error(error)
      })
    },
    promoGrossValue(promo) {
      if (promo.deposit_related == 1) {
        return promo.value * this.lastDeposit.amount / 100
      }
      return promo.value
    },
    checkTollerance() {
      const promoBunch = this.$parent.$apolloData.data.user_used_promotions.data
      if (promoBunch.length < 1) {
        return true
      }
      let firedSame = promoBunch.filter(pb => {pb.promotion_id == this.promoSelected.id});
      return firedSame.length < this.promoSelected.tollerance;
      // const releasedDates = _.pluck(promoBunch, 'updated_at')
      // return releasedDates.filter(d => new Date().getDate() === this.$datter.rawDate(d).getDate()).length < this.promoSelected.tollerance
    },
    checkWithdraws() {
      this.usersList = _.pluck(this.$parent.relatedUsers, 'id')
      this.usersList.push(this.$route.params.clientId)
      this.$apollo.query({
        query: gql`query($users:[ID!]){
          user_group_last_withdraw(
            users : $users,
            order_type:"WITHDRAW",
            status:["CONFIRMED", "PENDING", "PROCESSING"],
            limit:1
          ){
            created_at
          }
        }`,
        variables: {
          users: this.usersList,
        },
        client: 'cashioClient',
      }).then(data => {
        const ultimateLastWithDraw = data.data.user_group_last_withdraw[0]?.created_at
        if (ultimateLastWithDraw) {
          const lastDepositDate = this.$datter.rawDate(ultimateLastWithDraw)
          const now = new Date()
          const diff = Math.abs(lastDepositDate.getTime() - now.getTime()) / 3600000
          // if (diff >= 48) {
          if (true) {
            this.available = true
          } else {
            this.available = false
          }
        }
      }).catch(error => {
        console.error(error)
      })
    },
  },
}
</script>

<style>
  .b-sidebar-right{
      background: #161d31!important;
      width: 450px;
  }
  #sidebar-add-promotion___title__{
    text-align: center;
    width: 100%;
    display: block;
  }
  .hr-text {
    position: relative;
    border: none;
    height: 1px;
    background: #999;
}

.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 1rem;
    color: #999;
    border-radius: 30rem;
    padding: 0.2rem 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.pointer label{
  cursor: pointer;
}
.loading-bg{
  background: rgb(16,170,228, 50%);
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 3;
}
.info-box{
margin:30px 0 0;
}
.info-box h6{
  font-weight: bold;
  margin-bottom: 0;
}
.f-big{
  font-size: 18px;
}
.info-box p{

}

</style>
