<template>
  <div>
    <div v-if="!$apollo.loading">
      <h3>Cliente: {{user | userDocument("NICKNAME")}} <span v-if="user.documents.filter(d=> d.type == 'BLACKLIST').length > 0" id="blacklist-flag"><feather-icon size="25" class="mr-1" icon="AlertTriangleIcon" />ESTE CLIENTE ESTÁ NA BLACKLIST</span></h3>
      <client-list-info :wallets="wallets" :user="user" @openButtonSidebar="openButtonSidebar" @refreshUserData="refreshIdentity()" @showRelatedModal="showRelatedModal"/>
      <client-stats v-if="false" :movements="movements"/>
      <client-transactions @openSidebar="openDepositSidebar" :orders="{withdraws: withdraw_orders,pending_deposits: pending_deposits,confirmed_deposits: confirmed_deposits}" :key="this.key" :usedBonus="user_used_promotions" />
    </div>
    <div v-else>
      <logo-loader-animation/>
    </div>
  </div>
</template>

<script>
import { BCard, BTabs, BTab,BButton, BCardText, BLink, BFormInput, BCol, BRow,BModal} from 'bootstrap-vue'

import ClientDepositSidebar from "@/components/clients/ClientDepositSidebar.vue"
import ClientAddNotesSidebar from "@/components/clients/ClientAddNotesSidebar.vue"
import ClientChangeNameSidebar from "@/components/clients/ClientChangeNameSidebar.vue"
import ClientAddPromotionSidebar from "@/components/clients/ClientAddPromotionSidebar.vue"
import GeneralClientsList from "@/components/clients/GeneralClientsList.vue"
import GeneralGroupsList from "@/components/groups/GeneralGroupsList.vue"
import ClientStats from "@/components/clients/ClientStats.vue"
import ClientListInfo from "@/components/clients/ClientListInfo.vue"
import ClientTransactions from "@/components/clients/ClientTransactions.vue"
import ClientStickyNotes from "@/components/clients/ClientStickyNotes.vue"
import ClientAddRelatedModal from "@/components/clients/ClientAddRelatedModal.vue"
import ClientAddGroupModal from "@/components/clients/ClientAddGroupModal.vue"
import LogoLoaderAnimation from "@/components/misc/LogoLoaderAnimation.vue"
import gql from 'graphql-tag'
import {_} from 'vue-underscore';

export default {
  components: {
    BButton, 
    BTab,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BCol,
    BRow,
    BTabs,
    BModal,
    ClientDepositSidebar,
    ClientChangeNameSidebar,
    ClientAddPromotionSidebar,
    ClientStats,
    ClientAddRelatedModal,
    BLink,
    underscore: _,
    ClientAddNotesSidebar,
    ClientListInfo,
    ClientTransactions,
    ClientStickyNotes:ClientStickyNotes,
    GeneralClientsList,
    LogoLoaderAnimation,
    GeneralGroupsList,
    ClientAddGroupModal
  },
  data () {
    return {
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Customers',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '1.423k',
          subtitle: 'Products',
          customClass: 'mb-2 mb-sm-0',
        }
      ],
      user:{id:"0"},
      wallets:null,
      walletsToBeSearched : ["0"],
      depositObj:null,
      movements:null,
      openOrders:null,
      key:0,
      relatedUsers:[],
      withdraw_orders:null,
      rKey:1,
    }
  },
  apollo:{
    user_with_all_relations: {
      query: gql`query($userId:ID!){
        user_with_all_relations(id:$userId){
          id,
          username,
          status,
          created_at,
          documents{
            id,
            type,
            value
          },
          user_relations{
            id,
            relation_type{
              code
            },
            related_user{
              id,
              username,
              status,
              created_at,
              documents{
                id,
                type,
                value
              }
            },
            related_to_me{
              id,
              username,
              status,
              created_at,
              documents{
                id,
                type,
                value
              }
            }
          },
        }
      }`,
      client: 'identityClient',
      variables () {
        return {
            userId: this.$route.params.clientId,
        }
      }
    },
    user_notes: {
      query: gql`query($userId:ID!){
        user_notes(user_id:$userId){
          id,
          title,
          content,
          priority,
          created_at
        }
      }`,
      client: 'identityClient',
      variables () {
        return {
          userId: this.$route.params.clientId,
        }
      }
    },
    user_used_promotions: {
      query: gql`query($userId:ID!){
        user_used_promotions(first:20,user_id:$userId){
          data{
            transaction_id,
            value_released,
            created_at,
            promotion{
              id,
              name,
              title,
              wallet_type_id 
            }
          }
        }
      }`,
      client: 'identityClient',
      variables () {
        return {
            userId: this.$route.params.clientId,
        }
      }
    },
    wallets:{
      query: gql `query($userId:String!){
        wallets(first:100,user_id:$userId){
          data{
            user_id,
            wallet_code,
            wallet_type_id,
            balance,
            id
          },
        }
      }`,
      client: 'walletClient',
      variables () {
        return {
            userId: this.$route.params.clientId,
        }
      }
    },
    movements:{
      query: gql `query($containsWalletsIds:[String!], $containsMovementTypes:[Int!]){
        movements(first:100,containsWalletsIds:$containsWalletsIds, 
        containsMovementTypes:$containsMovementTypes){
          data{
            id,
            value,
            movement_type_id,
            wallet_id,
            created_at
          },
        }
      }`,
      client: 'walletClient',
      variables () {
        return {
          containsWalletsIds: this.walletsToBeSearched,
          containsMovementTypes: [1,2,8]
        }
      }
    },
    withdraw_orders:{
      query: gql `query($userId:ID){
      withdraw_orders: 
        orders(first:15,user_id:$userId, order_type:"WITHDRAW"){
          data{
            id,
            order_type,
            user_id,
            amount,
            status,
            created_at,
            updated_at,
            target_bank{
              bank_name,
              owner_name,
              account,
              agency
            },
            tags{
              key,
              value
            },
            provider{
              code
            }
          },
        }
      }`,
      client: 'cashioClient',
      variables () {
        return {
          userId: this.$route.params.clientId,
        }
      },
    },
    pending_deposits:{
      query: gql `query($userId:ID){
      pending_deposits: 
        orders(first:15,user_id:$userId, order_type:"DEPOSIT", status:"PENDING"){
          data{
            id,
            order_type,
            user_id,
            amount,
            status,
            created_at,
            updated_at,
            target_bank{
              bank_name,
              owner_name,
              account,
              agency
            },
            tags{
              key,
              value
            },
            provider{
              code
            }
          },
        }
      }`,
      client: 'cashioClient',
      variables () {
        return {
          userId: this.$route.params.clientId,
        }
      },
    },
    confirmed_deposits:{
      query: gql `query($userId:ID, $containStatus:[String!]){
      confirmed_deposits: 
        orders(first:15,user_id:$userId, order_type:"DEPOSIT", containStatus:$containStatus){
          data{
            id,
            order_type,
            user_id,
            amount,
            status,
            created_at,
            updated_at,
            target_bank{
              bank_name,
              owner_name,
              account,
              agency
            },
            tags{
              key,
              value
            },
            provider{
              code
            }
          },
        }
      }`,
      client: 'cashioClient',
      variables () {
        return {
          userId: this.$route.params.clientId,
          containStatus: ["CONFIRMED", "CONFIRMED_WITH_OBSERVATION"]
        }
      },
    },
    user_groups_by_user_id:{
      query: gql `query($userId:ID){
        user_groups_by_user_id(user_id:$userId){
          id,
          name,
          members_count,
          created_at
        }
      }`,
      client: 'identityClient',
      variables () {
        return {
          userId: this.$route.params.clientId,
        }
      },
    },
  },
  watch:{
    wallets: function(){
      let obj = _.pluck(this.wallets.data, 'id')
      this.walletsToBeSearched = obj
    },
    user_groups_by_user_id:function(){
      this.user_groups = this.user_groups_by_user_id;
    },
    user_with_all_relations:function(){
      this.user = this.user_with_all_relations
      let plucky = _.pluck(this.user.user_relations, 'related_user').filter(r => r.id != this.user.id)
      this.relatedUsers = _.pluck(this.user.user_relations, 'related_to_me').filter(r => r.id != this.user.id)
      this.relatedUsers.push(...plucky)
    },

  },
  methods:{
    openDepositSidebar(e,ref) {
      this.depositObj = e
      this.$refs[ref].toogleV()
    },
    refreshOrders(){
      this.$apolloProvider.clients.cashioClient.reFetchObservableQueries()
    },
    refreshIdentity(){
      this.$apolloProvider.clients.identityClient.reFetchObservableQueries()
    },
    openButtonSidebar(e){
      this.$refs[e].toogleV()

    },
    removeUserRelation(e){
      const res = this.$dialog.confirm({ title: 'Remover relação',text: 'Voce realmente gostaria de desvincular os usuários?', actions: [{text: 'Não',variant: 'red', key:false},{text: 'Sim, remover',variant: 'green', key:true}]}).then(function(valor) {
        if(valor === true){
          this.unsetRelation = e;
          this.$apollo.mutate({
            mutation: gql`mutation (
              $id: ID!,
            ){
              deleteUserRelation(
                id: $id,
              ){
                id
              }
            }`,
            variables: {
              id: this.unsetRelation,
            },
            client: "identityClient"
          }).then((data) => {
            this.$apolloProvider.clients.identityClient.reFetchObservableQueries()
          }).catch((error) => {
            console.error(error)
          })
        }
      }.bind(this), function(motivo) {
      // rejeitada
      });
    },
    showRelatedModal(){
      this.$refs['relatedModal'].$bvModal.show("user-related-modal")
    },
    showGroupModal(){
      this.$refs['groupModal'].$bvModal.show("user-group-modal")
    },
    refreshGroups(el_group){
      this.user_groups = el_group;
      this.rKey++;

    }
  },
  beforeCreate(){
    this.$route.params.clientId = atob(this.$route.params.clientId);

  }
}
</script>

<style>
  .act-button{
    min-width: 130px;
    display: block;
    margin: 10px auto;

  }
  #blacklist-flag{
    background:#ea5455;
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    margin: 10px 20px;
  }
</style>
