<template>
  <div>
    <b-card title="Transações financeiras">
      <b-tabs>
        <b-tab active title="Depósitos em aberto">
          <open-deposits-list @openSidebar="retransmitSidebar" :orders="orders.pending_deposits.data"/>
        </b-tab>
        <b-tab title="Depósitos">
          <deposits-list :orders="orders.confirmed_deposits.data" :noTaker="true"/>
        </b-tab>
        <b-tab title="Saques">
          <withdraws-list :orders="orders.withdraws.data"/>
        </b-tab>
        <b-tab title="Bônus">
          <bonus-list :items="usedBonus.data"/>
        </b-tab>
      </b-tabs> 
      <b-button v-if="$ability.can('manage', 'client') && $ability.can('be', 'company-employee')"  class="act-button mt-4" @click="openFinanceModal" variant="gradient-success">Resumo financeiro</b-button>
    </b-card>
    <finances-modal v-if="showFinances"></finances-modal>
  </div>
</template>

<script>
import {
  BTabs,BTab, BFormCheckbox, BButton, BCard, BRow, BCol, BBadge,BCardText, BLink
} from 'bootstrap-vue'
import DepositsList from "@/components/transactions/DepositsList.vue"
import OpenDepositsList from "@/components/transactions/OpenDepositsList.vue"
import WithdrawsList from "@/components/transactions/WithdrawsList.vue"
import BonusList from "@/components/transactions/BonusList.vue"
import financeModal from "@/components/transactions/FinancesModal.vue"
import FinancesModal from '@/components/transactions/FinancesModal.vue'
export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BLink,
    DepositsList,
    OpenDepositsList,
    WithdrawsList,
    BonusList,
    FinancesModal,
  },
  props:['orders', 'usedBonus'],
  data() {
    return {
      showFinances:false
    }
  },
  methods:{
    retransmitSidebar(e, ref){
      this.$emit('openSidebar',e, ref)
    },
    openFinanceModal(){
      this.showFinances=!this.showFinances;
    }
  },
  created(){
    // console.log(this.usedBonus)
  } 
}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
</style>